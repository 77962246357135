import React, { useEffect, useRef, useState, useMemo } from "react";

function Stats() {
  const statsRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const stats = useMemo(
    () => [
      { label: "Années D'expérience", value: 2 },
      { label: "Projets", value: 3 },
      { label: "Client Satisfait", value: 3 },
    ],
    []
  );

  const [displayValues, setDisplayValues] = useState(stats.map(() => 0));

  // Refs pour stocker les valeurs initiales et cibles de l'animation
  const initialValuesRef = useRef(displayValues);
  const targetValuesRef = useRef(stats.map((s) => s.value));
  const animationStartedRef = useRef(false);

  useEffect(() => {
    const currentRef = statsRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible || animationStartedRef.current) return;

    animationStartedRef.current = true; // On s'assure de ne lancer l'animation qu'une seule fois

    const duration = 2000;
    const start = performance.now();
    const initialValues = initialValuesRef.current;
    const targetValues = targetValuesRef.current;

    function animate(time) {
      const elapsed = time - start;
      const progress = Math.min(elapsed / duration, 1);

      const newValues = targetValues.map((target, i) => {
        const initial = initialValues[i];
        return Math.floor(initial + (target - initial) * progress);
      });

      setDisplayValues(newValues);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  }, [isVisible]); // On ne dépend plus de displayValues ici

  return (
    <section
      ref={statsRef}
      className="bg-black text-white py-16 px-4 flex justify-center"
    >
      <div className="max-w-6xl w-full flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="border border-gray-800 p-8 w-64 text-center"
          >
            <div className="text-4xl font-bold mb-2">
              {displayValues[index]}
            </div>
            <div className="text-sm">{stat.label}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Stats;
