import React from "react";
import {
  FaDesktop,
  FaShoppingCart,
  FaMobileAlt,
  FaSearch,
  FaPalette,
} from "react-icons/fa";
import { Helmet } from "react-helmet";

function Services() {
  const onePageServices = [
    {
      title: "Pack Essentiel",
      description:
        "Une page avec des sections essentielles : Accueil, À propos, Services, Témoignages, et Contact. Responsive et SEO de base.",
      price: "À partir de 300€",
      icon: (
        <FaDesktop
          className="text-red-500 text-3xl"
          aria-label="Icône d'écran desktop"
        />
      ),
    },
    {
      title: "Pack Pro",
      description:
        "Design personnalisé avec animations légères. Sections optimisées pour présenter vos services et témoignages. SEO amélioré.",
      price: "À partir de 600€",
      icon: (
        <FaPalette
          className="text-red-500 text-3xl"
          aria-label="Icône de palette de couleurs"
        />
      ),
    },
    {
      title: "Pack Premium",
      description:
        "Design sur mesure avec fonctionnalités avancées (formulaire interactif, réservation). Animations et SEO avancé.",
      price: "À partir de 1 000€",
      icon: (
        <FaMobileAlt
          className="text-red-500 text-3xl"
          aria-label="Icône de mobile"
        />
      ),
    },
  ];

  const siteVitrineServices = [
    {
      title: "Pack Starter",
      description:
        "3 pages essentielles : Accueil, À propos, Contact. Design responsive et SEO de base pour une visibilité en ligne efficace.",
      price: "À partir de 500€",
      icon: (
        <FaDesktop
          className="text-red-500 text-3xl"
          aria-label="Icône d'écran desktop"
        />
      ),
    },
    {
      title: "Pack Pro",
      description:
        "Jusqu'à 6 pages avec design personnalisé, galerie de photos, et blog intégré. SEO optimisé pour Google.",
      price: "À partir de 900€",
      icon: (
        <FaPalette
          className="text-red-500 text-3xl"
          aria-label="Icône de palette de couleurs"
        />
      ),
    },
    {
      title: "Pack Premium",
      description:
        "Pages illimitées, design sur mesure, et fonctionnalités avancées comme un e-commerce léger ou un portfolio complet.",
      price: "À partir de 1 500€",
      icon: (
        <FaShoppingCart
          className="text-red-500 text-3xl"
          aria-label="Icône de panier e-commerce"
        />
      ),
    },
  ];

  const eCommerceServices = [
    {
      title: "Pack Essentiel",
      description:
        "Site e-commerce simple avec gestion de produits de base. Design responsive et solutions de paiement sécurisé.",
      price: "À partir de 1 000€",
      icon: (
        <FaShoppingCart
          className="text-red-500 text-3xl"
          aria-label="Icône de panier e-commerce"
        />
      ),
    },
    {
      title: "Pack Pro",
      description:
        "Site e-commerce avec gestion avancée des produits, design personnalisé et intégration d'options de livraison.",
      price: "À partir de 1 500€",
      icon: (
        <FaSearch
          className="text-red-500 text-3xl"
          aria-label="Icône de loupe (recherche)"
        />
      ),
    },
    {
      title: "Pack Premium",
      description:
        "E-commerce sur mesure avec fonctionnalités avancées : gestion des stocks, suivi des commandes, et SEO optimisé.",
      price: "À partir de 2 500€",
      icon: (
        <FaMobileAlt
          className="text-red-500 text-3xl"
          aria-label="Icône de mobile"
        />
      ),
    },
  ];

  return (
    <section id="services" className="bg-black text-white py-16 px-4">
      <Helmet>
        <title>
          Mes Services - Création de Sites One Page, Vitrine & E-Commerce
        </title>
        <meta
          name="description"
          content="Découvrez mes services de création de sites web : One Page, Vitrine et E-Commerce. Des packs Essentiel, Pro et Premium adaptés à vos besoins."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            name: "Création de sites web",
            description:
              "Services de création de sites One Page, Vitrine et E-Commerce pour améliorer votre présence en ligne.",
            provider: {
              "@type": "Person",
              name: "Votre Nom ou Agence",
            },
            areaServed: "Monde",
            offers: [
              {
                "@type": "Offer",
                name: "Pack Essentiel One Page",
                description:
                  "Une page avec des sections essentielles : Accueil, À propos, Services, Témoignages, et Contact. Responsive et SEO de base.",
                price: "300",
                priceCurrency: "EUR",
              },
              {
                "@type": "Offer",
                name: "Pack Pro One Page",
                description:
                  "Design personnalisé avec animations légères. Sections optimisées pour présenter vos services et témoignages. SEO amélioré.",
                price: "600",
                priceCurrency: "EUR",
              },
              {
                "@type": "Offer",
                name: "Pack Premium One Page",
                description:
                  "Design sur mesure avec fonctionnalités avancées (formulaire interactif, réservation). Animations et SEO avancé.",
                price: "1000",
                priceCurrency: "EUR",
              },
            ],
          })}
        </script>
      </Helmet>

      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-12">
          MES SERVICES
        </h1>

        {/* One Page Services */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-red-500">
            Sites One Page
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {onePageServices.map((service, index) => (
              <div
                key={index}
                className="border border-gray-700 p-6 hover:border-red-500 transition duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="min-w-[50px] min-h-[50px] flex items-center justify-center border border-gray-700 p-2">
                    {service.icon}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                    <p className="text-sm leading-relaxed text-gray-300 mb-4">
                      {service.description}
                    </p>
                    <p className="text-red-500 font-bold">{service.price}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Site Vitrine Services */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-red-500">
            Sites Vitrine
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {siteVitrineServices.map((service, index) => (
              <div
                key={index}
                className="border border-gray-700 p-6 hover:border-red-500 transition duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="min-w-[50px] min-h-[50px] flex items-center justify-center border border-gray-700 p-2">
                    {service.icon}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                    <p className="text-sm leading-relaxed text-gray-300 mb-4">
                      {service.description}
                    </p>
                    <p className="text-red-500 font-bold">{service.price}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* E-Commerce Services */}
        <div>
          <h2 className="text-3xl font-bold mb-8 text-red-500">
            Sites E-Commerce
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {eCommerceServices.map((service, index) => (
              <div
                key={index}
                className="border border-gray-700 p-6 hover:border-red-500 transition duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="min-w-[50px] min-h-[50px] flex items-center justify-center border border-gray-700 p-2">
                    {service.icon}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                    <p className="text-sm leading-relaxed text-gray-300 mb-4">
                      {service.description}
                    </p>
                    <p className="text-red-500 font-bold">{service.price}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
