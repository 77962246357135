import React from "react";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Thomas Descamps - Concepteur de Solutions Web</title>
        <meta
          name="description"
          content="Thomas Descamps, concepteur de solutions web. Création de sites vitrines sur mesure, design moderne et référencement optimisé pour booster votre entreprise."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <header
        id="presentation"
        className="min-h-screen bg-gray-800 text-white flex flex-col justify-between relative"
      >
        <main className="flex-1 flex flex-col items-center justify-center text-center px-4 sm:px-8">
          <h1 className="text-3xl sm:text-6xl font-bold mb-4">
            Thomas Descamps
          </h1>
          <h2 className="text-base sm:text-2xl font-medium text-gray-300 mb-6">
            Concepteur de solution web
          </h2>
          <p className="max-w-xs sm:max-w-2xl text-gray-400 text-sm sm:text-lg leading-relaxed">
            Vous souhaitez donner un coup de boost à votre entreprise ? Je crée
            des sites vitrines sur mesure qui vous démarquent et attirent de
            nouveaux clients. Grâce à mon expertise en design web et en
            référencement, je vous garantis un site moderne, efficace et adapté
            à vos besoins.
          </p>
          <button
            onClick={() =>
              document
                .getElementById("contact")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="bg-red-500 text-white py-2 px-4 sm:py-3 sm:px-6 rounded-lg text-sm sm:text-lg hover:bg-red-600 mt-6"
          >
            Contactez Moi
          </button>
        </main>

        {/* Barre sociale au même niveau que le texte, même sur mobile */}
        <nav
          className="absolute top-1/2 right-4 sm:right-8 transform -translate-y-1/2 flex flex-col items-center"
          aria-label="Liens vers mes réseaux sociaux"
        >
          <div className="flex flex-col items-center">
            <span
              className="text-[10px] sm:text-sm text-gray-500 tracking-wider inline-block mb-2 sm:mb-4"
              style={{
                writingMode: "vertical-rl",
                textOrientation: "upright",
                whiteSpace: "nowrap",
              }}
            >
              Réseaux&nbsp;sociaux
            </span>
            <div className="w-px h-8 sm:h-20 bg-gray-500"></div>
          </div>
          <ul className="flex flex-col space-y-2 sm:space-y-4 mt-2 sm:mt-4">
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61567887872510"
                target="_blank"
                rel="noopener noreferrer"
                className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-full bg-gray-700 hover:bg-red-500 text-white transition duration-300"
                aria-label="Facebook - s'ouvre dans un nouvel onglet"
              >
                <i className="fab fa-facebook-f text-xs sm:text-lg"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/artduweb59/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-full bg-gray-700 hover:bg-red-500 text-white transition duration-300"
                aria-label="Instagram - s'ouvre dans un nouvel onglet"
              >
                <i className="fab fa-instagram text-xs sm:text-lg"></i>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Home;
