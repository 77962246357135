import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear(); // Obtenir l'année actuelle

  return (
    <footer className="bg-black text-white py-4 px-4 relative">
      {/* Conteneur texte centré */}
      <div className="max-w-6xl mx-auto text-center">
        <p className="text-sm">© {currentYear} - Tous droits réservés.</p>
        <p className="text-sm mt-1">
          Conçu par{" "}
          <a
            href="https://thomasdescamps.fr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-500 hover:text-red-600 transition-colors"
          >
            Thomas Descamps
          </a>
          .
        </p>
      </div>

      <a
        href="#presentation"
        className="bg-red-500 hover:bg-red-600 text-white w-10 h-10 flex items-center justify-center rounded-sm transition-colors fixed right-4 bottom-4 z-50"
        title="Retour en haut"
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 3l-7 7h4v7h6v-7h4l-7-7z" />
        </svg>
      </a>
    </footer>
  );
}

export default Footer;
