import React, { useState, useEffect } from "react";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Si on scroll de plus de 50px on considère que l'utilisateur a commencé à défiler
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`flex items-center justify-between px-6 py-4 sticky top-0 z-50 transition-colors duration-300 ${
        isScrolled ? "bg-white text-black shadow" : "bg-gray-800 text-white"
      } relative`}
      style={{ transition: "background-color 0.3s, color 0.3s" }}
    >
      {/* Logo */}
      <div className="text-lg font-bold">Thomas Descamps</div>

      {/* Bouton hamburger sur mobile */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="md:hidden focus:outline-none"
      >
        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
          {isOpen ? (
            // Icône "X" lorsque le menu est ouvert
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.293 6.293a1 1 0 011.414 0L12 10.586l4.293-4.293a1 
               1 0 111.414 1.414L13.414 12l4.293 4.293a1 
               1 0 01-1.414 1.414L12 13.414l-4.293 
               4.293a1 1 0 01-1.414-1.414L10.586 
               12 6.293 7.707a1 1 0 010-1.414z"
            />
          ) : (
            // Icône hamburger lorsque le menu est fermé
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 5h16a1 1 0 010 2H4a1 1 0 
               010-2zm0 6h16a1 1 0 010 
               2H4a1 1 0 010-2zm0 6h16a1 1 
               0 010 2H4a1 1 0 010-2z"
            />
          )}
        </svg>
      </button>

      {/* Menu sur écran large */}
      <ul className="hidden md:flex space-x-6">
        <li>
          <a
            href="#presentation"
            className={`hover:text-red-500 transition ${
              isScrolled ? "text-black" : "text-white"
            }`}
          >
            Présentation
          </a>
        </li>
        <li>
          <a href="#about" className="hover:text-red-500 transition">
            À Propos
          </a>
        </li>
        <li>
          <a href="#services" className="hover:text-red-500 transition">
            Services
          </a>
        </li>
        <li>
          <a href="#projects" className="hover:text-red-500 transition">
            Projets
          </a>
        </li>
        <li>
          <a href="#contact" className="hover:text-red-500 transition">
            Contact
          </a>
        </li>
      </ul>

      {/* Menu sur mobile (affiché quand isOpen est true) */}
      {isOpen && (
        <ul
          className={`absolute top-full left-0 w-full flex flex-col space-y-2 py-4 px-6 md:hidden z-10 shadow-lg ${
            isScrolled ? "bg-white text-black" : "bg-gray-800 text-white"
          } transition-colors duration-300`}
        >
          <li>
            <a
              href="#presentation"
              className="block hover:text-red-500 transition"
              onClick={() => setIsOpen(false)}
            >
              Présentation
            </a>
          </li>
          <li>
            <a
              href="#about"
              className="block hover:text-red-500 transition"
              onClick={() => setIsOpen(false)}
            >
              À Propos
            </a>
          </li>
          <li>
            <a
              href="#services"
              className="block hover:text-red-500 transition"
              onClick={() => setIsOpen(false)}
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="#projects"
              className="block hover:text-red-500 transition"
              onClick={() => setIsOpen(false)}
            >
              Projets
            </a>
          </li>
          <li>
            <a
              href="#review"
              className="block hover:text-red-500 transition"
              onClick={() => setIsOpen(false)}
            >
              Review
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="block hover:text-red-500 transition"
              onClick={() => setIsOpen(false)}
            >
              Contact
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
